import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "server";
import {
  getAccessToken,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
// import { dispatchToast } from "@utils";

type TrpcClientCustomProps = {
  selectedCompany: ReturnType<typeof useSelectedCompany>["selectedCompany"];
};

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient({
  // defaultOptions: {
  //   mutations: {
  //     onError: (error) => {
  //       console.log("error on mutation ", error);
  //       dispatchToast({ content: "teste mutation", type: "warning" });
  //     },
  //   },
  //   queries: {
  //     onError: (error) => {
  //       console.log("error on queries ", error);
  //       dispatchToast({ content: "teste query", type: "warning" });
  //     },
  //   },
  // },
});

export const trpcClient = ({ selectedCompany }: TrpcClientCustomProps) =>
  trpc.createClient({
    links: [
      httpBatchLink({
        url: `${process.env.BFF_URL}/trpc`,
        // url: "http://localhost:3000/trpc",
        headers: async () => {
          const token = await getAccessToken();
          return {
            Authorization: token,
            "X-Selected-Company": selectedCompany?.id,
          };
        },
      }),
    ],
  });
