import { useEffect, useState } from "react";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";
import CertificateBuilder from "../../../components/CertificateBuilder";
import { track } from "../../../utils/segment";
import { GenerateButton, GenerateButtonArea } from "./styled";

import { dispatchToast, getCertificateAndDownload } from "@utils";

const GenerateCertificate = ({ course, isPreview }) => {
  const [certificateDate, setCertificateDate] = useState<any>(null);
  const [certificateId, setCertificateId] = useState<any>(null);
  const [certificate, setCertificate] = useState<any>({});
  const [employeeName, setEmployeeName] = useState<string>("");

  const [hasClicked, setHasClicked] = useState<any>(false);

  const { mutate: setMonitoringMutate } =
    trpc.monitorings.setMonitoring.useMutation({});

  const { isFetching: isGettingEmployeeById } =
    trpc.employee.getEmployeeByIdFromCompany.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {
        setEmployeeName(data?.name);
      },
    });

  const { isFetching: isGettingCourseMonitoring } =
    trpc.monitorings.getCourseMonitoring.useQuery(
      { courseId: course?._id },
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!course?._id && !!!isPreview && !!hasClicked,
        onSuccess: (value: any) => {
          if (!value.length) {
            setMonitoringMutate({
              courseId: course?._id,
              params: {
                type: "course",
                certificateId: course?.certificateId,
                done: true,
              },
            });

            setCertificateDate(new Date());
            setCertificateId(course?.certificateId);
          } else {
            if (course?.doneClasses === course?.classesTotal) {
              setCertificateDate(value[0]?.createdAt);
              setCertificateId(value[0]?.certificateId);
            }
          }
        },
        onError: () => {
          setHasClicked(false);

          dispatchToast({
            type: "error",
            content:
              "Não foi possível gerar o certificado. Por favor, tente novamente",
          });
        },
      }
    );

  const { isFetching: isGettingCertificateById } =
    trpc.certificate.getCertificateById.useQuery(
      { _id: certificateId },
      {
        enabled:
          !!certificateId && !!certificateDate && !!!isPreview && !!hasClicked,
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data) => {
          setCertificate(data);
        },
        onError: () => {
          setHasClicked(false);

          dispatchToast({
            type: "error",
            content:
              "Não foi possível gerar o certificado. Por favor, tente novamente!",
          });
        },
      }
    );

  useEffect(() => {
    if (Object.keys(certificate).length && hasClicked) {
      getCertificateAndDownload({ name: course?.name });

      setHasClicked(false);
    }
  }, [certificate, hasClicked]);

  return (
    <>
      <GenerateButtonArea>
        <GenerateButton
          disabled={
            Math.round((course?.doneClasses / course?.classesTotal) * 100) ===
            100
              ? false
              : true
          }
          loading={
            isGettingCertificateById ||
            isGettingCourseMonitoring ||
            isGettingEmployeeById
          }
          onClick={() => {
            setHasClicked(true);

            track({
              name: "employee_lms_course_downloadcertificate_clicked",
            });
          }}
          variant="primary"
          size="medium"
        >
          <Icons name="IconCertificate" fill="transparent" />
          Baixar certificado
        </GenerateButton>
      </GenerateButtonArea>

      <CertificateBuilder
        courseName={course?.name}
        totalHours={course?.totalHours}
        employeeName={employeeName}
        signText={certificate?.signText}
        signerName={certificate?.signerName}
        signerPostion={certificate?.signerPostion}
        expiresDate={certificate?.expiresDate}
        companyLogo={certificate?.companyLogo}
        companyName={certificate?.companyName}
        certificateDate={certificateDate}
      />
    </>
  );
};

export default GenerateCertificate;
