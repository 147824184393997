import { useEffect, useMemo, useState } from "react";

import {
  DataGrid,
  Skeleton,
  dayjs,
  Icons,
  EmptyState,
} from "@flash-tecnologia/hros-web-ui-v2";

import { track, pageSizeOptions, getCertificateAndDownload } from "@utils";

import { BannerArea, BannerTitle, GenerateButton, PreviewArea } from "./styled";

import { NoMyCourse } from "../../NoMyCourse";
import CertificatePreview from "@components/CertificatePreview";
import CertificateBuilder from "@components/CertificateBuilder";

interface TableGridProps {
  data: any[];
  rawData: any[];
  employeeName: string;
  pageSize: number;
  loading: boolean;
  refetch: () => void;
}

export const CertificateTable = ({
  data,
  rawData,
  employeeName = "",
  pageSize,
  loading,
  refetch,
}: TableGridProps) => {
  const [certificateInfo, setCertificateInfo] = useState<any>({});

  useEffect(() => {
    if (Object.keys(certificateInfo).length)
      getCertificateAndDownload({ name: certificateInfo?.name });
  }, [certificateInfo]);

  const columns = useMemo(
    () => [
      {
        Header: "Treinamento",
        accessor: "certificates",
        Cell: ({
          row: {
            original: { course },
          },
        }) => {
          return (
            <BannerArea>
              <PreviewArea>
                <CertificatePreview
                  data={{
                    courseName: course?.name ?? null,
                    companyLogo: course?.companyLogo,
                    companyName: course?.companyName,
                    signText: course?.signText,
                    signerName: course?.signerName,
                    signerPostion: course?.signerPostion,
                    expiresDate: course?.expiresDate,
                  }}
                />
              </PreviewArea>
              <div>
                <BannerTitle variant="body3">
                  {course?.name ?? "Sem nome"}
                </BannerTitle>
              </div>
            </BannerArea>
          );
        },
      },
      {
        Header: "Emitido em",
        accessor: "createdAt",
        Cell: ({
          row: {
            original: { createdAt = null },
          },
        }) => (
          <BannerTitle variant="body3">
            {createdAt ? dayjs(new Date(createdAt)).format("DD/MM/YYYY") : "-"}
          </BannerTitle>
        ),
      },
      {
        Header: "Ações",
        accessor: "actions",
        sticky: "right",
        disableSortBy: true,
        Cell: ({
          row: {
            original: {
              createdAt = null,
              course = {} as any,
              certificate = {} as any,
            },
          },
        }) => {
          return (
            <GenerateButton
              onClick={async () => {
                setCertificateInfo({
                  name: course?.name ?? "Sem nome",
                  totalHours: course?.totalHours,
                  signText: certificate?.signText,
                  signerName: certificate?.signerName,
                  signerPostion: certificate?.signerPostion,
                  expiresDate: certificate?.expiresDate,
                  companyLogo: certificate?.companyLogo,
                  companyName: certificate?.companyName,
                  certificateDate: createdAt,
                });
                track({
                  name: "employee_lms_mycourses_downloadcertificate_clicked",
                });
              }}
              size="large"
              variant="primary"
            >
              <Icons name="IconCertificate" fill="transparent" />
              Baixar certificado
            </GenerateButton>
          );
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={<NoMyCourse />}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum certificado encontrado.",
        }}
        pageSizeOptions={pageSizeOptions}
      />
      <CertificateBuilder
        courseName={certificateInfo?.name}
        totalHours={certificateInfo?.totalHours}
        employeeName={employeeName}
        signText={certificateInfo?.signText}
        signerName={certificateInfo?.signerName}
        signerPostion={certificateInfo?.signerPostion}
        expiresDate={certificateInfo?.expiresDate}
        companyLogo={certificateInfo?.companyLogo}
        companyName={certificateInfo?.companyName}
        certificateDate={certificateInfo?.certificateDate}
      />
    </>
  );
};
