import { useCallback, useMemo, useState } from "react";

import { CourseTab } from "../CourseTab";
import { CertificateTab } from "../CertificateTab";
import { CardCarousel } from "../CardCarousel";
import { CourseMetrics } from "../CourseMetrics";

import { Tab, useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";
import { getFromSS, setInSS } from "@flash-tecnologia/hros-web-utility";
import { NoMyCourse } from "../NoMyCourse";

export const Content = ({
  content,
  filters,
  certificateContent,
  certificateFilters,
  highlights,
  isLoading,
  employeeName,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const courseTypeView = useMemo(
    () => getFromSS("lms_manage_create_course_type_view"),
    []
  );

  const [typeView, setTypeView] = useState<any>(courseTypeView || "list");

  const handleSetTypeView = useCallback(
    (type: string) => {
      setInSS({ key: "lms_manage_create_course_type_view", value: type });
      setTypeView(type);
    },
    [typeView]
  );

  if (!content?.length) return <NoMyCourse />;

  const renderCarouselAndMetrics = () => {
    if (isMobile) return <></>;

    return (
      <>
        {highlights?.length ? <CardCarousel items={highlights} /> : null}
        <CourseMetrics courses={content} certificates={certificateContent} />
      </>
    );
  };

  return (
    <>
      {renderCarouselAndMetrics()}
      <Tab
        defaultTab={0}
        tabItens={[
          {
            label: `Meus treinamentos (${content?.length ?? 0})`,
            component: (
              <CourseTab
                data={content}
                filters={filters}
                loading={isLoading}
                typeView={typeView}
                handleSetTypeView={handleSetTypeView}
                isMobile={isMobile}
              />
            ),
          },
          {
            label: `Meus certificados (${certificateContent?.length ?? 0})`,
            component: (
              <CertificateTab
                data={certificateContent}
                filters={certificateFilters}
                loading={isLoading}
                typeView={typeView}
                handleSetTypeView={handleSetTypeView}
                isMobile={isMobile}
                employeeName={employeeName}
              />
            ),
          },
        ]}
      />
    </>
  );
};
